@import "aos/dist/aos.css";
@import "@wazoe/elements/styles/elements";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-text;
  overflow-x: hidden;
}

.prose > p {
  margin: 0;
}

.prose > ol {
  margin: 0;
}

.prose > ul {
  margin: 0;
}
